<template>
  <div class="home">
    <!-- 加载中 -->
    <!-- <div class="cover-wrap" v-show="loading_switch"></div>
    <div class="loading-box" v-show="loading_switch">
      <van-loading vertical class="loading" color="#fff">系统加载中，请稍后...</van-loading>
    </div> -->

    <div class="header-wrap">
      <div class="head-tip">我们将竭诚为您解决问题。您可以点击查看下方的提示，自助解决常见问题。如需更多帮助，请点击“电话咨询”，联系人工客服处理。</div>
    </div>

    <div class="content-wrap">
      <!-- <div class="fun-box">
        <div class="fun-item" @click="handleRefundOnline()">
          <img src="https://bcloud.aitici.com/aipaintingdouying/image/20230726/7f7ed44829b2c7fd846a3c1745fb1e6d.png" alt="fun2" />
        </div>

        <a href="tel:4001666606" @click="eventTracking('2')">
          <div class="fun-item">
            <img src="../../assets/image/fun1.png" alt="fun1" />
          </div>
        </a>

        <div class="fun-item" @click="handleGoComplaint()">
          <img src="https://bcloud.aitici.com/aipaintingdouying/image/20230726/2de6124ebeeb2c1782e840cb077c5e20.png" alt="fun3" />
        </div>
      </div> -->

      <div class="q-box">
        <div class="q-title">常见问题</div>
        <!-- <div class="q-list"> -->
        <!-- <div class="item" @click="handleGoFQA(1)">
            <div class="item-l">我为什么被扣款了</div>
            <div class="item-r">
              <img src="../../assets/image/yjt.png" alt="youjiantou" />
            </div>
          </div> -->

        <!-- <div class="item" @click="handleGoFQA(4)">
            <div class="item-l">退款后还会继续扣费吗</div>
            <div class="item-r">
              <img src="../../assets/image/yjt.png" alt="youjiantou" />
            </div>
          </div> -->

        <!-- <div class="item" @click="handleGoFQA(3)">
            <div class="item-l">交易怎么进行退款</div>
            <div class="item-r">
              <img src="../../assets/image/yjt.png" alt="youjiantou" />
            </div>
          </div> -->

        <!-- <div class="item" @click="handleGoFQA(5)">
            <div class="item-l">退款后还能继续使用APP吗</div>
            <div class="item-r">
              <img src="../../assets/image/yjt.png" alt="youjiantou" />
            </div>
          </div> -->

        <!-- <div class="item" @click="handleGoFQA(9)">
            <div class="item-l">权益码有什么用处</div>
            <div class="item-r">
              <img src="../../assets/image/yjt.png" alt="youjiantou" />
            </div>
          </div> -->

        <!-- <div class="item" @click="handleGoFQA(2)">
            <div class="item-l">怎么关闭免密支付/自动扣款</div>
            <div class="item-r">
              <img src="../../assets/image/yjt.png" alt="youjiantou" />
            </div>
          </div> -->

        <!-- <div class="item" @click="handleGoFQA(6)">
            <div class="item-l">如何取消设置来电秀</div>
            <div class="item-r">
              <img src="../../assets/image/yjt.png" alt="youjiantou" />
            </div>
          </div> -->

        <!-- <div class="item" @click="handleGoFQA(7)">
            <div class="item-l">设置来电秀之后没有生效怎么办</div>
            <div class="item-r">
              <img src="../../assets/image/yjt.png" alt="youjiantou" />
            </div>
          </div> -->

        <!-- <div class="item" @click="handleGoFQA(8)">
            <div class="item-l">保存的视频和图片在哪里找到</div>
            <div class="item-r">
              <img src="../../assets/image/yjt.png" alt="youjiantou" />
            </div>
          </div> -->
        <!-- </div> -->

        <van-collapse class="q-list" v-model="activeName" accordion :border="false" @change="handleClickFQA()">
          <van-collapse-item title="我为什么被扣款了" name="1">
            <div class="q-tip">本APP的自动续费扣款是因为您购买或试用了连续订阅套餐，同时授权同意了自动续费协议，使用期间可随时取消，当期会员到期后会按照自动续费协议进行续费，所以才产生这笔会员扣款。</div>
          </van-collapse-item>
          <van-collapse-item title="交易怎么进行退款" name="2">
            <div class="q-tip">本APP的会员服务属于虚拟商品，基于虚拟商品的特性及其价值，暂不支持退款。如果您开通了自动续费服务，可以随时取消自动续费，后续就不会再扣款了。</div>
            <div class="q-tip">取消自动续费的操作步骤为：打开支付宝客户端-点击我的-（右上角）设置-支付设置-免密支付/自动扣款，即可查看代扣签约记录列表；点击进入协议页面，您可自助取消代扣协议。</div>
          </van-collapse-item>
          <!-- <van-collapse-item title="退款后还会继续扣费吗" name="3">
            <div class="q-tip">您提交退款后，自动续费服务将自动关闭，不会再继续扣费。</div>
          </van-collapse-item> -->
          <van-collapse-item title="怎么关闭免密支付/自动扣款" name="4">
            <div class="q-tip">如您需要查看代扣签约协议记录或取消代扣协议，请打开：支付宝客户端-我的-（右上角）设置-支付设置-免密支付/自动扣款，即可查看代扣签约记录列表；点击进入协议页面，您可查看签约详情或自助取消代扣协议。</div>
            <!-- <div class="q-tip">您可点击本页面的【在线自助退款】或【电话极速退款】，快速取消自动扣款。</div> -->
          </van-collapse-item>
          <van-collapse-item title="取消自动续费后还能继续使用APP吗" name="5">
            <div class="q-tip">取消自动续费后，会员到期时不会再自动延长，您可以继续使用APP的免费功能。</div>
            <div class="q-tip">如需使用会员专属功能，则需要重新开通会员。操作步骤为：打开APP，点击“我的”，然后点击“开通会员”。</div>
          </van-collapse-item>

          <van-collapse-item title="如何取消设置来电秀" name="6">
            <div class="q-tip">可以通过2个途径关闭来电秀。</div>
            <div class="q-tip">1.在【我的】页面找到【来电显示】，点击进入后，右上角会有是否开启，直接关闭则取消来电显示的设置了。</div>
            <div class="q-tip">2.找到手机中的【设置】-【声音和震动】中切换您的来电铃声，并且在【相册】中，找到您的来电显示视频，即可关闭来电显示。</div>
          </van-collapse-item>

          <van-collapse-item title="保存的视频和图片在哪里找到" name="7">
            <div class="q-tip">保存好的视频或者图片，可以在手机相册中找到。</div>
            <div class="q-tip">因为每款手机机型不同，相册可能在不同位置，找到自己手机的相册，查看最新的照片/视频即可。</div>
          </van-collapse-item>
          
        </van-collapse>
      </div>
    </div>

    <div class="footer-wrap">
      <div class="btn-box">
        <a href="tel:4001515306" @click="eventTracking('5')">
          <div class="btn-l">电话咨询</div>
        </a>
        <!-- <div class="btn-r" @click="handleGoChat()">
          <img src="../../assets/image/qian_icon.png" alt="qian_icon" />
          <span>在线客服</span>
        </div> -->
      </div>

      <div class="phone-tip">客服热线: 400-151-5306</div>
    </div>

    <!-- <iframe :src="aa"></iframe> -->
  </div>
</template>

<script>
export default {
  name: 'HomeView',
  data() {
    return {
      loading_switch: false,
      timer: null,
      activeName: '',
    };
  },

  created() {},

  destroyed() {
    clearTimeout(this.timer);
  },

  methods: {
    //猜你想问
    handleGoFQA(index) {
      let place = '4';
      this.eventTracking(place);
      this.$router.push({ name: 'question', query: { id: index } });
    },

    /**
     * 猜你想问-手风琴
     */
    handleClickFQA() {
      let place = '4';
      this.eventTracking(place);
    },

    //提交投诉
    handleGoComplaint() {
      let place = '3';
      this.eventTracking(place);
      this.$router.push('./oldcomplaint');
    },

    //在线自助退款
    handleRefundOnline() {
      let place = '1';
      this.eventTracking(place);
      sessionStorage.removeItem('statue');
      sessionStorage.removeItem('btnStatue');
      sessionStorage.removeItem('orderList');
      sessionStorage.removeItem('report_list');
      // this.$toast('在线退款升级中，请选择电话极速退款');
      this.$router.push({ name: 'online' });
    },

    //在线客服
    async handleGoChat() {
      let place = '6';
      this.eventTracking(place);
      this.loading_switch = true;
      let info = { uid: '', data: [], gid: '482707004' };
      let cond = `url=http://test776.aitici.com/index.php/index/Sevenfish/sevenfishList&is_post=0`;
      let result = await this.$axios
        .post(`https://complaint-service.aitici.com/index/index/zhuanfa`, cond, {
          headers: {
            'content-type': 'application/x-www-form-urlencoded',
          },
        })
        .catch(function (error) {
          console.log(error);
        });
      // console.log('客户分组', result.data.data);
      if (result.data) {
        if (result.data.data.code == 200) {
          result.data.data.data.forEach((item, index) => {
            if (item.type == 3) {
              info.gid = item.h5_id;
            }
          });
        }
      }

      info.data = [{ key: 'real_name', value: '支付宝' }];

      await this.fishkefu.Config(info);
      let qysdk = await this.fishkefu.openSdk();
      if (qysdk == 'sdk尚未加载成功，请稍后再试') {
        this.timer = null;
        this.timer = setTimeout(async () => {
          await this.fishkefu.Config(info);
          let qysdk1 = await this.fishkefu.openSdk();
          this.loading_switch = false;
          if (qysdk1 == 'sdk尚未加载成功，请稍后再试') {
            alert('sdk尚未加载成功，请稍后再试');
          }
        }, 3000);
      } else {
        this.loading_switch = false;
      }
    },

    //数据埋点
    async eventTracking(place) {
      let res = await this.$axios.post('https://middlegrounpcomplaint.juzan.info/api/access_log/add', {
        place,
      });
      // console.log('查看埋点数据', res);
      if (place == '2' || place == '5') {
        let taskId = localStorage.getItem('taskId');
        this.reportEvent(taskId);
      }
    },

    //埋点数据上报
    async reportEvent(c_no) {
      let res = await this.$axios.post('https://middlegrounpcomplaint.juzan.info/api/event/index', {
        event: 'cs_click',
        attr: '3',
        c_no: c_no,
        source: 1,
      });
    },
  },
};
</script>

<style lang="less">
// iframe {
//   width: 80vw;
//   height: 80vh;
//   position: fixed;
//   top: 0;
//   left: 0;
// }
@import url('./home.less');
</style>
